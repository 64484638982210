import React, { useState, useEffect } from 'react';
import './UserEditModal.css';

interface User {
  username: string;
  name: string;
  role: string;
  email: string;
  company: string;
  password?: string; // Adiciona a propriedade opcional de senha
}

interface UserEditModalProps {
  user: User;
  onSave: (user: User) => void; // Certifique-se de que onSave espera um parâmetro User
  onClose: () => void;
}

const UserEditModal: React.FC<UserEditModalProps> = ({ user, onSave, onClose }) => {
  console.log('UserEditModal: Rendering with user:', user);
   
  const [formData, setFormData] = useState<User>({ ...user, password: '' });
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  useEffect(() => {
    console.log('UserEditModal: formData state initialized:', formData);
  }, [formData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`UserEditModal: Changing ${name} to ${value}`);
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prevState => ({
      ...prevState,
      password: e.target.value,
    }));
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (formData.password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    console.log('UserEditModal: Submitting form with data:', formData);
    onSave(formData); // Passa os dados do formulário para onSave
  };

  const handleCancel = () => {
    console.log('UserEditModal: Cancel button clicked');
    onClose();
  };

  return (
    <div className="custom-modal">
      <div className="modal-content">
        <h2>{user.username ? 'Editar' : 'Incluir'}</h2>
        <label>
          Username:
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            disabled={!!user.username} // Desabilita se estiver editando um usuário existente
          />
        </label>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        <label>
          Role:
          <input
            type="text"
            name="role"
            value={formData.role}
            onChange={handleChange}
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </label>
        <label>
          Company:
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handlePasswordChange}
          />
        </label>
        <label>
          Confirm Password:
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </label>
        <div className="modal-actions">
          <button onClick={handleSubmit}>Save</button>
          <button onClick={handleCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default UserEditModal;
