import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Tenants.css';
import TenantEditModal from './TenantEditModal';

interface Tenant {
  id: number;
  description: string;
  creation_date: string;
  creation_time: string;
  created_by: string;
  created_at: string;
}

const Tenants: React.FC = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await axios.get('/api/tenants');
        setTenants(response.data);
      } catch (error) {
        console.error('Error fetching tenants:', error);
      }
    };

    fetchTenants();
  }, []);

  const handleSaveTenant = async (tenant: Tenant): Promise<void> => {
    try {
      // Your save logic here
    } catch (error) {
      console.error('Error saving tenant:', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTenant(null);
  };

  return (
    <div>
      {isModalOpen && selectedTenant && (
        <TenantEditModal
          tenant={selectedTenant}
          onSave={handleSaveTenant}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};
export type { Tenant };
export default Tenants;