import React from 'react';
import { Link } from 'react-router-dom';
import './HeaderBar.css';

interface HeaderBarProps {
  username: string;
  onLogout: () => void;
}

const HeaderBar: React.FC<HeaderBarProps> = ({ username, onLogout }) => {
  const isAdmin = username.toLowerCase() === 'admin';

  return (
    <div className="header-bar">
      <div className="app-title">DeskFlow</div>
      <div className="config-menu">
        <button className="config-button">Config</button>
        <div className="dropdown-content">
          <Link to="/tenants" className={isAdmin ? '' : 'dimmed-link'}>Ambientes</Link>
          <Link to="/users" className={isAdmin ? '' : 'dimmed-link'}>Usuários</Link>
        </div>
      </div>
      <button className="logout-button" onClick={onLogout}>
        Logout
      </button>
    </div>
  );
};

export default HeaderBar;

