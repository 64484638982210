import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import './Monitor.css';

ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface ServerInfo {
  cpu: {
    percent: number;
  };
  disk: {
    free: number;
    percent: number;
    total: number;
    used: number;
  };
  memory: {
    available: number;
    free: number;
    percent: number;
    total: number;
    used: number;
  };
}

const Monitor: React.FC = () => {
  const [serverInfo, setServerInfo] = useState<ServerInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch server info from the /serverinfo endpoint
  useEffect(() => {
    const fetchServerInfo = async () => {
      try {
        const response = await axios.get<ServerInfo>('/api/server-info');
        setServerInfo(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch server info');
        setLoading(false);
        console.error('Error fetching server info:', error);
      }
    };

    fetchServerInfo();
  }, []);

  if (loading) {
    return <div>Loading server info...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!serverInfo) {
    return <div>No data available</div>;
  }

  // Data for the memory usage chart
  const memoryData = {
    labels: ['Used', 'Free'],
    datasets: [
      {
        label: 'Memory Usage',
        data: [serverInfo.memory.used / (1024 * 1024 * 1024), serverInfo.memory.free / (1024 * 1024 * 1024)], // Convert to GB
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  // Data for the disk usage chart
  const diskData = {
    labels: ['Used', 'Free'],
    datasets: [
      {
        label: 'Disk Usage',
        data: [serverInfo.disk.used / (1024 * 1024 * 1024), serverInfo.disk.free / (1024 * 1024 * 1024)], // Convert to GB
        backgroundColor: ['#FFCE56', '#4BC0C0'],
      },
    ],
  };

  // Data for the CPU usage chart
  const cpuData = {
    labels: ['CPU Usage'],
    datasets: [
      {
        label: 'CPU',
        data: [serverInfo.cpu.percent, 100 - serverInfo.cpu.percent],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  return (
    <div className="monitor-container">
      <h2>Monitor</h2>
      <div className="chart-container">
        <div className="chart-item">
          <h3>Memória</h3>
          <Doughnut data={memoryData} />
        </div>
        <div className="chart-item">
          <h3>Uso de Disco</h3>
          <Doughnut data={diskData} />
        </div>
        <div className="chart-item">
          <h3>% CPU</h3>
          <Doughnut data={cpuData} />
        </div>
      </div>
    </div>
  );
};

export default Monitor;
