import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UserEditModal from './UserEditModal';
import './UserManagement.css';

interface User {
  username: string;
  name: string;
  role: string;
  email: string;
  company: string;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Check if the user is an admin
  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username?.toLowerCase() === 'admin') {
      setIsAdmin(true);
    }
  }, []);

  // Fetch the list of users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get<User[]>('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setIsNewUser(false);
    setIsModalOpen(true);
  };

  const handleAddUser = () => {
    const newUser: User = {
      username: '',
      name: '',
      role: '',
      email: '',
      company: ''
    };

    setSelectedUser(newUser);
    setIsNewUser(true);
    setIsModalOpen(true);
  };

  const handleDeleteUser = async (username: string) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/api/users/${username}`);
        setUsers(users.filter(user => user.username !== username));
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const handleSaveUser = async (user: User) => {
    try {
      if (isNewUser) {
        await axios.post('/api/users', user);
        setUsers([...users, user]);
      } else {
        await axios.put(`/api/users/${user.username}`, user);
        setUsers(users.map(u => (u.username === user.username ? user : u)));
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Render nothing if the user is not an admin
  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {isModalOpen && selectedUser ? (
        <UserEditModal
          user={selectedUser}
          onSave={handleSaveUser}
          onClose={handleCloseModal}
        />
      ) : (
        <div className="user-management">
          <h2>Usuários</h2>
          <button className="add-user-button" onClick={handleAddUser}>
            Incluir usuário
          </button>
          <table className="user-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Company</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td>{user.name}</td>
                  <td>{user.role}</td>
                  <td>{user.email}</td>
                  <td>{user.company}</td>
                  <td>
                    <button className="edit-button" onClick={() => handleEditUser(user)}>
                      Edit
                    </button>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteUser(user.username)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default UserManagement;
