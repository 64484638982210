import React from 'react';
import { Tenant } from './Tenants';

interface TenantEditModalProps {
  tenant: Tenant;
  onSave: (tenant: Tenant) => Promise<void>; // Update to return Promise<void>
  onClose: () => void;
}

const TenantEditModal: React.FC<TenantEditModalProps> = ({ tenant, onSave, onClose }) => {
  const handleSave = async () => {
    await onSave(tenant);
    onClose();
  };

  return (
    <div className="modal">
      <h2>Edit Tenant</h2>
      {/* Your modal content here */}
      <button onClick={handleSave}>Save</button>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default TenantEditModal;