import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Log.css';

interface LogEntry {
  id: number;
  date: string;
  time: string;
  finish_date: string;
  finish_time: string;
  user: string;
  tenant: string;
  service: string;
  action: string;
  origin: string;
  destination: string;
  result: string;
}

const Log: React.FC = () => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchLogs = async (page: number) => {
      try {
        const response = await axios.get<{ logs: LogEntry[], totalPages: number }>(`/api/logs?page=${page}?per_page=17`);
        setLogs(response.data.logs);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs(currentPage);
  }, [currentPage]);

  const getStatusColor = (result: string) => {
    if (result.toLowerCase().includes('success')) return 'green';
    if (result.toLowerCase().includes('in progress')) return 'orange';
    if (result.toLowerCase().includes('error')) return 'red';
    return 'grey';
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="log-container">
      <h3>Action Log</h3>
      <table className="log-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Result</th>
            <th>Date</th>
            <th>Time</th>
            <th>Finish Date</th>
            <th>Finish Time</th>
            <th>User</th>
            <th>Tenant</th>
            <th>Service</th>
            <th>Action</th>
            <th>Origin</th>
            <th>Destination</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <td>{log.id}</td>
              <td>
                <span
                  className="status-indicator"
                  style={{ backgroundColor: getStatusColor(log.result) }}
                />
                <span className="status-text">{log.result}</span>
              </td>
              <td>{log.date}</td>
              <td>{log.time}</td>
              <td>{log.finish_date}</td>
              <td>{log.finish_time}</td>
              <td>{log.user}</td>
              <td>{log.tenant}</td>
              <td>{log.service}</td>
              <td>{log.action}</td>
              <td>{log.origin}</td>
              <td>{log.destination}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button className="log-button" 
          onClick={handlePreviousPage} 
          disabled={currentPage === 1} 
        >
          Anterior
        </button>
        <span>Pág. {currentPage} de {totalPages}</span>
        <button className="log-button" onClick={handleNextPage} disabled={currentPage === totalPages} 
        >
          Próximo
        </button>
      </div>
    </div>
  );
};

export default Log;
