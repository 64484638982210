import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Sidebar.css';
import logo from '../assets/images/logo.png'; // Import the logo image

interface SidebarProps {
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTenant: React.Dispatch<React.SetStateAction<{ id: string; name: string } | null>>;
  nameSelectedTenant: React.Dispatch<React.SetStateAction<string | null>>;
  username: string;
}

interface Tenant {
  id: string;
  name: string;
}

const Sidebar: React.FC<SidebarProps> = ({ setActivePage, setSelectedTenant, nameSelectedTenant, username }) => {
  const [tenants, setTenants] = useState<Tenant[]>([]); // State to hold tenants data
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Added loading state
  const [userName, setUserName] = useState<string | null>(null); // State for the username
  const isAdmin = username.toLowerCase() === 'admin';

  // Fetch user info from the /userinfo endpoint using username as a URL parameter
  useEffect(() => {
    const fetchUserInfo = async () => {
      const username = localStorage.getItem('username'); // Get the username from localStorage
      const token = localStorage.getItem('access_token'); // Get the token from localStorage

      if (!username || !token) {
        setError('No username or token found. Please log in.');
        return;
      }

      try {
        const response = await axios.get(`/api/userinfo/${username}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token for authentication
          },
        });

        setUserName(response.data.name); // Assuming the API returns user info as { name: 'User Name' }
      } catch (error) {
        setError('Failed to fetch user info');
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  // Fetch tenants from the API
  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Get the token from localStorage

        if (!token) {
          setError('No token found. Please log in.');
          setLoading(false);
          return;
        }

        const response = await axios.get('/api/get-tenants', {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token for authentication
          },
        });

        setTenants(response.data.tenants); // Assuming the API returns tenants as { tenants: [{id, name}] }
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch tenants');
        console.error('Error fetching tenants:', error);
        setLoading(false);
      }
    };

    fetchTenants();
  }, []);

  // Handle logout

  return (
    <div className="sidebar">
      <ul className="sidebar-menu">
        <li className="sidebar-menu-item">
          <span>Opções</span>
          <hr />
          <ul className="sidebar-submenu">
            <li className="sidebar-submenu-item" onClick={() => setActivePage('home')}><b>Home</b></li>

            <li
              className={`sidebar-submenu-item ${!isAdmin ? 'disabled' : ''}`}
              onClick={isAdmin ? () => setActivePage('users') : undefined}
            >
              Usuários
            </li>

            <li className="sidebar-submenu-item" onClick={() => setActivePage('log')}>Log</li>
            <b>Ambientes</b>
            {loading ? (
              <li className="sidebar-submenu-item">Loading tenants...</li>
            ) : tenants.length > 0 ? (
              tenants.map((tenant) => (
                <li
                  key={tenant.id}
                  className="sidebar-submenu-item"
                  onClick={() => {
                    setSelectedTenant({ id: tenant.id, name: tenant.name });
                    setActivePage('services');
                  }}
                >
                  - {tenant.name}
                </li>
              ))
            ) : (
              <li className="sidebar-submenu-item">No tenants available</li>
            )}
          </ul>
        </li>
      </ul>
      {error && <p className="sidebar-error">{error}</p>}
      <div className="sidebar-logo-container">
        <p>Powered by</p>
        <img src={logo} className="sidebar-logo" alt="Logo" />
      </div>
    </div>
  );
};

export default Sidebar;
