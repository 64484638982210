import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './Services.css';
import Log from './Log';

interface Service {
  Name: string;
  DisplayName: string;
  Status: string;
}

interface ServicesProps {
  selectedTenantId: string | null;
  selectedTenantName: string | null;
}

const Services: React.FC<ServicesProps> = ({ selectedTenantId, selectedTenantName }) => {
  const [services, setServices] = useState<Service[]>([]);
  const [logMessages, setLogMessages] = useState<string[]>([]);
  const [showLog, setShowLog] = useState<boolean>(false);
  const [isLoadingService, setIsLoadingService] = useState<string | null>(null);
  const [showBalloon, setShowBalloon] = useState<boolean>(false);
  const [showWait, setShowWait] = useState<boolean>(false);
  const [environments, setEnvironments] = useState<string[]>([]); // Initialize as an empty array
  const [originEnv, setOriginEnv] = useState<string>('');
  const [destEnv, setDestEnv] = useState<string>('');
  const [showRpoDialog, setShowRpoDialog] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<string | null>(null);

  const getBaseUrl = () => {
    return ''; // Adjust this according to your backend server setup
  };

  // Fetch services for the selected tenant
  const fetchServices = useCallback(async () => {
    if (!selectedTenantId) return; // If no tenant is selected, do nothing
    try {
      const response = await axios.get<Service[]>(`${getBaseUrl()}/api/get-services/${selectedTenantId}`);
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  }, [selectedTenantId]);

  // Fetch environments for the selected tenant
  const fetchEnvironments = useCallback(async () => {
    if (!selectedTenantId) return; // If no tenant is selected, do nothing
    try {
      // Fetch environments for the selected tenant
      const response = await axios.get<{ environments: string[] }>(`${getBaseUrl()}/api/get-environments/${selectedTenantId}`);
      setEnvironments(response.data.environments || []); // Ensure it's an array or fallback to an empty array
    } catch (error) {
      console.error('Error fetching environments:', error);
    }
  }, [selectedTenantId]);

  useEffect(() => {
    fetchServices();
    fetchEnvironments();
  }, [fetchServices, fetchEnvironments]);

  const addLogMessage = (message: string) => {
    setLogMessages((prevMessages) => [...prevMessages, message]);
  };

  // Function to create a new log entry
  const createLogEntry = async (serviceName: string, action: string, origin?: string, destination?: string) => {
    try {
      const now = new Date();
      const logEntry = {
        date: now.toISOString().split('T')[0],
        time: now.toTimeString().split(' ')[0],
        user: localStorage.getItem('username') || 'unknown', // Assuming you store username in localStorage
        tenant: selectedTenantName || 'unknown',
        service: serviceName,
        action: action,
        origin: origin || '',
        destination: destination || '',
        result: 'In Progress'
      };
      const response = await axios.post(`${getBaseUrl()}/api/logs`, logEntry);
      return response.data.id; // Assuming the response includes the log ID
    } catch (error) {
      console.error('Error creating log entry:', error);
      return null;
    }
  };

  // Function to update the log entry with the finish time and result
  const updateLogEntry = async (logId: number, result: string) => {
    try {
      const now = new Date();
      await axios.put(`${getBaseUrl()}/api/logs/${logId}`, {
        finish_date: now.toISOString().split('T')[0],
        finish_time: now.toLocaleTimeString('en-US', { hour12: false }),
        result: result
      });
    } catch (error) {
      console.error('Error updating log entry:', error);
    }
  };

  const executeServiceAction = async (serviceName: string, action: 'start' | 'stop') => {
    setIsLoadingService(serviceName);
    setSelectedService(null);

    setServices((prevServices) =>
      prevServices.map((service) =>
        service.Name === serviceName ? { ...service, Status: 'Updating' } : service
      )
    );

    const logId = await createLogEntry(serviceName, `${action} service`);

    try {
      await axios.post(`${getBaseUrl()}/api/${action}-service`, { serviceName });
      addLogMessage(`Service ${serviceName} ${action}ed successfully.`);
      fetchServices();
      setShowBalloon(true); // Show green balloon
      setTimeout(() => setShowBalloon(false), 3000); // Hide after 3 seconds
      if (logId) await updateLogEntry(logId, 'Success');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      addLogMessage(`Error ${action}ing service ${serviceName}: ${errorMessage}`);
      console.error(`Error ${action}ing service:`, error);
      if (logId) await updateLogEntry(logId, 'Failed');
    } finally {
      setIsLoadingService(null);
    }
  };

  const handleExclusiveAction = async (action: 'on' | 'off') => {
    const logId = await createLogEntry('Exclusive Mode', `Set exclusive mode ${action}`);
    setServices((prevServices) =>
      prevServices.map((service) => ({ ...service, Status: 'Updating' }))
    );

    try {
      await axios.post(`${getBaseUrl()}/api/exclusive-mode-${action}/${selectedTenantId}`);
      addLogMessage(`Exclusive Mode Activated successfully.`);
      fetchServices();
      setShowBalloon(true); // Show green balloon
      setTimeout(() => setShowBalloon(false), 3000); // Hide after 3 seconds
      if (logId) await updateLogEntry(logId, 'Success');
    } catch (error) {
      console.error(`Error in exclusive mode ${action}:`, error);
      if (logId) await updateLogEntry(logId, 'Failed');
    }
  };

  const handleBulkAction = async (action: 'start-all' | 'stop-all' | 'restart-all') => {
    const logId = await createLogEntry('Bulk Action', action.replace('-', ' '));
    setServices((prevServices) =>
      prevServices.map((service) => ({ ...service, Status: 'Updating' }))
    );

    try {
      await axios.post(`${getBaseUrl()}/api/${action}/${selectedTenantId}`);
      addLogMessage(`${action.replace('-', ' ')} executed successfully.`);
      fetchServices(); // Refresh the services after the action is completed
      setShowBalloon(true); // Show green balloon
      setTimeout(() => setShowBalloon(false), 3000); // Hide after 3 seconds
      if (logId) await updateLogEntry(logId, 'Success');
    } catch (error) {
      console.error(`Error in bulk action: ${action}`, error);
      addLogMessage(`Error executing ${action}: ${error}`);
      if (logId) await updateLogEntry(logId, 'Failed');
    }
  };

  const promoteRpo = async () => {
    // Ensure environments are fetched before promotion
    if (!environments || environments.length === 0) {
      await fetchEnvironments();
    }

    try {
      if (originEnv && destEnv && originEnv !== destEnv) {
        const Promote = { tenant: selectedTenantId, origin: originEnv, destination: destEnv };
        const logId = await createLogEntry('Promote RPO', 'Promote', originEnv, destEnv);

        // Set all services to 'Updating' status
        setServices((prevServices) =>
          prevServices.map((service) => ({ ...service, Status: 'Updating' }))
        );

        try {
          await axios.post(`${getBaseUrl()}/api/promote`, Promote);
          addLogMessage(`RPO promovido de ${originEnv} para ${destEnv}.`);
          if (logId) await updateLogEntry(logId, 'Success');
        } catch (error) {
          console.error('Error promoting RPO:', error);
          addLogMessage(`Error promoting RPO: ${error}`);
          if (logId) await updateLogEntry(logId, 'Failed');
        } finally {
          // Fetch the updated services to return their status to the original content
          await fetchServices();
          setShowRpoDialog(false);
        }
        setOriginEnv('');
        setDestEnv('');
      } else {
        addLogMessage('Ambientes de origem e destino devem ser diferentes.');
      }
    } catch (error) {
      console.error('Error promoting RPO:', error);
    }
  };

  const getStatusColor = (status: string) => {
    if (status === 'Running') return 'green';
    if (status === 'Stopped') return 'red';
    if (status === 'Updating') return 'orange';
    return 'grey';
  };

  // Function to download the log file
  const downloadLog = async (serviceName: string) => {
    try {
      setShowWait(true); // Show the "wait" message while downloading the file
      const response = await axios.get(`${getBaseUrl()}/api/get-log/${serviceName}`, {
        responseType: 'blob', // Important for downloading files
      });

      // Create a link element, use it to download the file, and then remove it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${serviceName}_log.txt`); // Or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowWait(true); //
  
    } catch (error) {
      console.error('Error downloading log file:', error);
    }
    setShowWait(false); //
  };

  return (
    <div className="services">
      <h2>Serviços {selectedTenantId ? `( ${selectedTenantName} )` : ''}</h2>

      {showBalloon && <div className="success-balloon">Ação executada com sucesso!</div>}

      {showWait && <div className="success-balloon">Aguarde...</div>}

      <div className="content-wrapper">
        <div className="action-buttons-container">
          <div className="action-buttons">
            <button onClick={() => handleExclusiveAction('on')}>Ativar Modo Exclusivo</button>
            <button onClick={() => handleBulkAction('start-all')}>Iniciar Todos</button>
            <button onClick={() => handleBulkAction('stop-all')}>Parar Todos</button>
            <button onClick={() => handleBulkAction('restart-all')}>Reiniciar Serviços</button>
            <button onClick={() => setShowRpoDialog(true)}>Promover RPO</button>
          </div>
        </div>

        {showRpoDialog && (
          <div className="rpo-dialog">
            <h3>Promover RPO</h3>
            <label>
              RPO Origem:
              <select
                value={originEnv}
                onChange={(e) => setOriginEnv(e.target.value)}
              >
                <option value="">Selecione</option>
                {environments.map((environment, index) => (
                  <option key={index} value={environment}>
                    {environment}
                  </option>
                ))}
              </select>
            </label>
            <label>
              RPO Destino:
              <select
                value={destEnv}
                onChange={(e) => setDestEnv(e.target.value)}
              >
                <option value="">Selecione</option>
                {environments
                  .filter((environment) => environment !== originEnv)
                  .map((environment, index) => (
                    <option key={index} value={environment}>
                      {environment}
                    </option>
                  ))}
              </select>
            </label>
            <button onClick={promoteRpo}>Executar</button>
            <button onClick={() => setShowRpoDialog(false)}>Cancelar</button>
          </div>
        )}

        <table className="services-table">
          <thead>
            <tr>
              <th>&nbsp;ID</th>
              <th>Status</th>
              <th>Name</th>
              <th>Display Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service, index) => (
              <tr key={service.Name} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                <td>{index + 1}</td>
                <td>
                  <span
                    className="status-indicator"
                    style={{ backgroundColor: getStatusColor(service.Status) }}
                  />
                  <span>{service.Status}</span>
                </td>
                <td>{service.Name}</td>
                <td>{service.DisplayName}</td>
                <td>
                  <button className="service-button" onClick={() => executeServiceAction(service.Name, 'start')}>
                    Start
                  </button> &nbsp;
                  <button className="service-button" onClick={() => executeServiceAction(service.Name, 'stop')}>
                    Stop
                  </button> &nbsp;
                  <button className="service-button" onClick={() => downloadLog(service.Name)}>
                    Log
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Services;
