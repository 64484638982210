import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Services from './Services';
import Monitor from './Monitor';
import HeaderBar from './HeaderBar';
import Log from './Log';
import './Deskflow.css';
import UserManagement from './UserManagement';

const Deskflow: React.FC = () => {
  const [activePage, setActivePage] = useState<string>('home');
  const [selectedTenant, setSelectedTenant] = useState<{ id: string; name: string } | null>(null);
  const [selectedTenantName, setNameSelectedTenant] = useState<string | null>(null);
  const username: string = localStorage.getItem('username') || '';

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username'); // Clear the username as well
    window.location.href = '/'; // Redirect to the login page
  };

  const renderPage = () => {
    if (activePage === 'services' && selectedTenant) {
      return <Services selectedTenantId={selectedTenant.id} selectedTenantName={selectedTenant.name} />;
    }
    if (activePage === 'users') {
      return <UserManagement />;
    }
    if (activePage === 'home') {
      return <Monitor />;
    }
    if (activePage === 'log' ) {
      return <Log />;
    }
    return <div>Bem-vindo ao DeskFlow!!!</div>;
  };

  return (
    <div className="deskflow">
      <HeaderBar username={username}  onLogout={handleLogout} />
      <div className="main-content">
        <Sidebar username={username} setActivePage={setActivePage} setSelectedTenant={setSelectedTenant} nameSelectedTenant={function (value: React.SetStateAction<string | null>): void {
          throw new Error('Function not implemented.');
        } } />
        <div className="page-content">{renderPage()}</div>
      </div>
    </div>
  );
}

export default Deskflow;
