import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import io from 'socket.io-client';
import Login from './components/Login';
import Deskflow from './components/Deskflow';
import ProtectedRoute from './components/ProtectedRoute';
import UserManagement from './components/UserManagement'; 
import Tenants from './components/Tenants'; 
import 'bootstrap/dist/css/bootstrap.min.css';

const SocketContext = createContext(null);

export const useSocket = () => {
    return useContext(SocketContext);
};

function App() {
        return (
                        <Router>
                                <Routes>
                                        <Route path="/" element={<Login />} />
                                        <Route
                                                path="/deskflow"
                                                element={
                                                        <ProtectedRoute>
                                                                <Deskflow />
                                                        </ProtectedRoute>
                                                }
                                        />
                                        <Route
                                                path="/users"
                                                element={
                                                        <ProtectedRoute>
                                                                <UserManagement />
                                                        </ProtectedRoute>
                                                }
                                        />
                                        <Route
                                                path="/tenants"
                                                element={
                                                        <ProtectedRoute>
                                                                <Tenants />
                                                        </ProtectedRoute>
                                                }
                                        />
                                        {/* Other routes can be added here */}
                                </Routes>
                        </Router>
        );
}

export default App;
